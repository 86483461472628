(function ($, Drupal) {



    // initiates smoothScroll (A lightweight script to animate scrolling to anchor links) with basic settings
    // smoothScroll.init({
    //   selector: '[data-scroll]', // Selector for links (must be a class, ID, data attribute, or element tag)
    //   selectorHeader: null, // Selector for fixed headers (must be a valid CSS selector) [optional]
    //   speed: 900, // Integer. How fast to complete the scroll in milliseconds
    //   easing: 'easeInOutCubic', // Easing pattern to use
    //   offset: 150, // Integer. How far to offset the scrolling anchor location in pixels
    //   callback: function(anchor, toggle) {} // Function to run after scrolling
    // });

    // initiates gumshoe (A simple, framework-agnostic scrollspy script) with basic settings
    // gumshoe.init({
    //   selector: '[data-gumshoe] a', // Default link selector (must use a valid CSS selector)
    //   selectorHeader: '[data-gumshoe-header]', // Fixed header selector (must use a valid CSS selector)
    //   container: window, // The element to spy on scrolling in (must be a valid DOM Node)
    //   offset: 100, // Distance in pixels to offset calculations
    //   activeClass: 'active', // Class to apply to active navigation link and it's parent list item
    //   callback: function(nav) {} // Callback to run after setting active link
    // });


    // adds an "active" class to the header on scroll (300px)
    $(window).scroll(function () {
        headerScroll();
    });

    // $(function () {
    //     updateSkewSections();
    // });
    //
    // $(window).resize(function () {
    //     updateSkewSections();
    // });

    function headerScroll() {
        var scroll = $(window).scrollTop();
        if (scroll >= 300) {
            $('.header').addClass('active');
        } else {
            $('.header').removeClass('active');
        }
    }

    // function updateSkewSections() {
    //
    //     var mq = window.matchMedia("(min-width: 1025px)"),
    //         margin;
    //
    //     $('.section--skew').each(function (i, e) {
    //         var $section = $(e),
    //             height = e.offsetHeight,
    //             ratio = 21.7391304348;
    //
    //         if (mq.matches) {
    //             margin = -(height / ratio);
    //
    //             $section.css({
    //                 marginLeft: margin,
    //                 marginRight: margin,
    //             });
    //
    //         } else {
    //             margin = 0;
    //         }
    //
    //
    //     });
    // }

    $(function () {

        var $target = $('.event-popout__meta--left__social .share-link');

        $target.on('click', function (event) {
            event.preventDefault();
            var $el = $(this);

            $el.closest('.event-popout__meta--left')
                .find('.event-popout__meta--left__sharebox').slideToggle(function() {

                $(this).find('input')[0].select();
            });

        })
    })

    $(function() {
        $('#calendar-list .events-popout-wrapper').hide();

        $('#calendar-list .flex-wrapper').on('click', function(e) {
            var popout = $(e.currentTarget).next();
            popout.slideToggle();
        });

        $('#calendar-list .close').on('click', function(e) {
            var popout = $(this).closest('.events-popout-wrapper');

            popout.slideToggle();
        });


    })


})(jQuery, Drupal);
